import { Component } from '@angular/core';

@Component({
  selector: 'app-list-remark',
  templateUrl: './list-remark.component.html',
  styleUrls: ['./list-remark.component.scss'],
})
export class ListRemarkComponent {

  constructor() { }

}
